/* --------------------------------
   start screen
-------------------------------- */

.start-screen
{
	position: relative;
	z-index: 1;

	&__slider
	{
		> .slick-dots
		{
			position: absolute;
			bottom: 15px;
			left: 0;
			right: 0;
			text-align: center;
		}
	}

	&__slide
	{
		position: relative;
		display: flex !important;
		flex-wrap: wrap;

		width: 100%;
		height: calc(100vh - 65px);

		padding-top: 40px;
		padding-bottom: 40px;

		.__bg
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -1;
		}
	}

	&__slider-nav
	{
		display: none;
		line-height: 1;
		color: #c7c7c7;

		@include media-breakpoint-up(md)
		{
			display: block;
		}

		&--ver
		{
			text-align: center;

			.slick-dots
			{
				li
				{
					display: block;
					margin-top: 15px;
					margin-left: 0;

					&:first-child { margin-top: 0; }
				}
			}

			> span { display: block; }

			> b
			{
				width: 1px;
				height: 110px;
				margin: 25px 0;
			}
		}

		&--hor
		{
			> span { display: inline-block; }

			> b
			{
				width: 110px;
				height: 1px;
				margin: 0 25px;
			}

			.slick-dots
			{
				display: inline-block;
				vertical-align: middle;
			}
		}

		> * { direction: ltr; }

		> span
		{
			vertical-align: middle;
			font-size: 1.3rem;
			font-weight: 600;
		}

		> b
		{
			display: inline-block;
			vertical-align: middle;
			background-color: currentColor;
		}
	}
}

.start-screen--style-1
{
	.start-screen__slide
	{
		min-height: 400px;

		@include media-breakpoint-up(sm)
		{
			height: 750px;
			max-height: calc(100vh - 85px);
		}
	}

	.start-screen__slide--1
	{
		.__title
		{
			line-height: 0.9;
			font-size: 4rem;
			font-weight: 700;
			letter-spacing: -0.05em;

			span { font-weight: 200; }

			@include media-breakpoint-up(sm)
			{
				font-size: 5.5rem;
			}

			@include media-breakpoint-up(md)
			{
				font-size: 6.5rem;
			}

			@include media-breakpoint-up(lg)
			{
				font-size: 7rem;
			}

			@include media-breakpoint-up(xl)
			{
				font-size: 8rem;
			}
		}
	}

	.start-screen__slide--2
	{
		.__title
		{
			line-height: 0.9;
			font-size: 3.5rem;
			font-weight: 700;
			letter-spacing: -0.05em;

			span { font-weight: 200; }

			@include media-breakpoint-up(sm)
			{
				font-size: 4.5rem;
			}

			@include media-breakpoint-up(md)
			{
				font-size: 6rem;
			}
		}
	}

	.start-screen__slide--3
	{
		.__title
		{
			line-height: 1;
			font-size: 3rem;
			font-weight: 400;
			font-family: 'Fredericka the Great', cursive;
			color: #333;
			letter-spacing: -0.05em;

			@include media-breakpoint-up(sm)
			{
				font-size: 4.5rem;
			}

			@include media-breakpoint-up(md)
			{
				font-size: 6rem;
			}

			@include media-breakpoint-up(lg)
			{
				font-size: 7.5rem;
			}
		}
	}

	.start-screen__slider-nav
	{
		position: absolute;
		top: 30%;
		left: 10px;

		@media (min-width: 1300px)
		{
			left: 25px;
		}
	}

	.start-screen__panel
	{
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: #25272a;
		padding: 37px 50px;

		@include media-breakpoint-up(md)
		{
			display: block;
		}

		@include media-breakpoint-up(lg)
		{
			padding: 37px 70px;
		}

		.contact-info
		{
			margin-left: -40px;

			> li
			{
				display: inline-block;
				vertical-align: top;
				margin-top: 0;
				margin-left: 40px;
			}

			@include media-breakpoint-up(lg)
			{
				margin-left: -70px;

				> li { margin-left: 70px; }
			}
		}

		.video-block
		{
			position: absolute;
			top: 50%;
			left: 100%;
			white-space: nowrap;
			transform: translateY(-50%) translateX(-28px);
		}
	}
}

.start-screen--style-2
{
	@include min-screen(1300px)
	{
		margin-top: -50px;
	}

	.start-screen__slide
	{
		min-height: 400px;

		@include media-breakpoint-up(sm)
		{
			height: 750px;
			max-height: calc(100vh - 85px);
		}
	}

	.start-screen__slide--1 {}

	.start-screen__slide--2 {}

	.start-screen__slide--3
	{
		.__title
		{
			font-weight: 400;
			font-family: 'Fredericka the Great', cursive;
			color: #333;
		}
	}

	.start-screen__slider-nav
	{
		
	}

	.__title
	{
		line-height: 0.9;
		font-size: 3.5rem;
		font-weight: 700;
		letter-spacing: -0.05em;

		span { font-weight: 200; }

		@include media-breakpoint-up(sm)
		{
			font-size: 4.5rem;
		}

		@include media-breakpoint-up(md)
		{
			font-size: 6rem;
		}
	}

	.start-screen__panel
	{
		@include media-breakpoint-up(md)
		{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.video-block
	{
		padding: 25px 0;
		background-color: #fafafa;
		text-align: center;

		@include media-breakpoint-up(md)
		{
			width: 310px;
			margin-left: auto;
			padding: 37px 0;
		}
	}
}

.start-screen--style-3
{
	.start-screen__slide
	{
		min-height: 400px;

		@include media-breakpoint-up(sm)
		{
			height: 620px;
			max-height: calc(100vh - 85px);
		}
	}

	.start-screen__slide--1
	{
		.__title
		{
			font-weight: 400;
			font-family: 'Fredericka the Great', cursive;
			color: #333;
		}
	}

	.start-screen__slide--2 {}

	.start-screen__slide--3 {}

	.start-screen__slider-nav
	{
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);

		@media (min-width: 1300px)
		{
			left: 25px;
		}
	}

	.__title
	{
		line-height: 1;
		font-size: 3rem;
		font-weight: 700;
		letter-spacing: -0.05em;

		span { font-weight: 200; }

		@include media-breakpoint-up(sm)
		{
			font-size: 4.5rem;
		}

		@include media-breakpoint-up(md)
		{
			font-size: 6rem;
		}

		@include media-breakpoint-up(lg)
		{
			font-size: 7.5rem;
		}

		@include media-breakpoint-up(xl)
		{
			
		}
	}

	.img-shape {}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{

}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}