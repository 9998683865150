/* --------------------------------
   hero
-------------------------------- */

.hero
{
	background-color: $primary-color;
	@extend %bg-cover;
	color: $white;
	@include userSelect(none);

	&__inner
	{
		display: flex;
		align-items: center;
		height: 300px;
		padding-top: 30px;
		padding-bottom: 30px;

		@include media-breakpoint-up(sm)
		{
			height: 400px;
		}
	}

	&__title,
	&__subtitle
	{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&__title
	{
		line-height: 1.1;
		color: inherit;
	}

	&__subtitle
	{
		color: $primary-color;
		font-family: $fontFamily-secondary;
		font-weight: 600;
	}
}

.page-shop
{
	.hero
	{
		&__inner { height: 265px; }

		&__subtitle { color: $white; }
	}
}

.page-404
{
	.hero
	{
		background-position: 40% 50%;

		&__inner
		{
			flex-direction: column;
			height: auto;
			min-height: 100vh;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}